import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable, Subject} from 'rxjs';
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from '@angular/router';
import {RequestDefinitionsModel} from '../model/requestDefinitions.model';
import{userModel} from '../model/user.model';
import{LoginModel}from '../model/login.model';
import { MessageBoxComponent } from '../message-box/message-box.component';
import { MessageBoxHeaderComponent } from '../message-box-header/message-box-header.component';
import { MatDialog } from '@angular/material/dialog';
import { CookieService } from 'ngx-cookie-service';
import { catchError, tap } from "rxjs/operators";

//import { AuthService } from '../auth/auth-service.service';
@Injectable({
  providedIn: 'root'
})
export class SharedService {  
  readonly API_URL: string;
  readonly ISR_URL: string; 
  readonly Domain_URL: string; 

  readonly Test_API_URL: string = 'https://isrtest.enn.ericsson.net/rh-service/';
  readonly Prod_API_URL: string = 'https://isr.enn.ericsson.net/rh-service/';
  showSpinner:any='';
  public messageBoxMsg:string ="";  
  public messageBoxHeaderMsg:string ="";  

  
  constructor(private _http: HttpClient, private spinnerService:NgxSpinnerService, 
    private router:Router,private dialog: MatDialog,private cookieService:CookieService){
      if(document.baseURI.indexOf("localhost")!=-1) {
        this.API_URL = 'http://localhost:61308/';
        //this.API_URL = 'http://localhost:61163/';
        //this.API_URL = 'http://localhost:50239/';
        //this.ISR_URL = 'https://isrtest.enn.ericsson.net/';
        this.ISR_URL = 'http://localhost:56875/';  
        this.Domain_URL = 'localhost';            
      }   
      else if(document.baseURI.indexOf("isrtest")!=-1) {          
          this.API_URL = 'https://isrtest.enn.ericsson.net/rh-service/';
          this.ISR_URL = 'https://isrtest.enn.ericsson.net/';   
          this.Domain_URL = 'isrtest.enn.ericsson.net'        
      }
      else if(document.baseURI.indexOf("isr")!=-1) {       
          this.API_URL = 'https://isr.enn.ericsson.net/rh-service/'; 
          this.ISR_URL = 'https://isr.enn.ericsson.net/';    
          this.Domain_URL = 'isr.enn.ericsson.net'      
      }             
  }
  GetRequestDefinitions(ItmId):Observable<any>{        
    return this._http.get<RequestDefinitionsModel>(this.API_URL + '/api/ISR/request/GetRequestDefinitionById/' + ItmId );
  };
  GetUserByEmail(userModel):Observable<any>{            
    return this._http.post<userModel>(this.API_URL + '/api/ISR/request/PostISTUsersByEmail/', userModel);    
  };
  // GetUserByEmail(userModel){            
  //   return this._http.post<userModel>(this.API_URL + '/api/ISR/request/PostISTUsersByEmail/', userModel);    
  // };
  // async GetUserByEmail(userModel){
  //   const data = await this._http.post<userModel>(this.API_URL + '/api/ISR/request/PostISTUsersByEmail/', userModel).toPromise();
  //   return data;
  // }
  // public GetUserByEmail<userModel>(userModel): Promise<userModel> { 
	// 	return  this._http.post<userModel>(this.API_URL + '/api/ISR/request/PostISTUsersByEmail/', userModel).toPromise();
	// } 
  GetLoginData(userModel):Observable<any>{        
    return this._http.post<any>(this.API_URL + '/api/ISR/request/PostLoginData/' , userModel );
  };
  addBusinessDays(noOfdays) {        
    var today = new Date();
    var day = today.getDay();
    today.setDate(today.getDate() + noOfdays + (day === 6 ? 2 : +!day) + (Math.floor((noOfdays - 1 + (day % 6 || 1)) / 5) * 2));
    return today;    
  };
  gotoNextView(route,reqData,reqDefinitions,userModel,loginModel) {  
    this.router.navigate(['/' + route], {state: {reqData,reqDefinitions,userModel,loginModel}});  
  };
  // CheckForSUFWChanges(userID):Observable<any>{
  //     return this._http.get<any[]>(this.API_URL + '/api/ISR/requestOverview/CheckForSUFWChanges/'+ userID);
  //   };   

  getUserPermission(userModel):Observable<any>{            
    return this._http.post<userModel>(this.API_URL + 'api/user/getUserPermission/', userModel);    
  };

   chkUserPerm =function(cid){
    var userPermission= window.localStorage.getItem("userPermission");
    var userPerm= JSON.parse(userPermission);
    if(userPerm.length > 0) {
        var k=0;
        for (var i=0; i <= userPerm.length - 1; i++) {
            if (userPerm[i].codePermissionID == cid) {
                k++;
            }
        }
        if(k>0){
            return true;
        }
        else {
            return false;
        }
    }
    else {
        return false
    }
}

showMessageBox(messageValue){
this.messageBoxMsg = messageValue;
this.dialog.open(MessageBoxComponent);
}

showMessageBoxHeader(headerValue,messageValue){
  this.messageBoxHeaderMsg = headerValue;
  this.messageBoxMsg = messageValue;
  this.dialog.open(MessageBoxHeaderComponent);
}

public getIPAddress2()  
  {  
    return this._http.get("https://ipv4.myexternalip.com/json")
  }  
  public getIPAddress1()  
  {  
    return this._http.get("https://geolocation-db.com/json/")    
  }  

async getClientIpAddress():Promise<Observable<any>>
{  
  let ipaddress: Observable<any> | PromiseLike<Observable<any>>;
  ipaddress = this._http.get<any>('https://geolocation-db.com/json/')
    .pipe(
      catchError(err => {
        return "";
      }),
      tap(response => {
        return response.IPv4;
      })
    );
    return ipaddress;
}

logout(){    
  this.clearStorage();
  window.localStorage.setItem('isAuthorized','false');
  this.router.navigate(['/login']);
  //window.location.href = this.sharedService.ISR_URL + "Default.aspx?rq=logout";
}

clearStorage()
{
  window.localStorage.clear();
  window.sessionStorage.clear();
  this.cookieService.deleteAll();
  this.cookieService.deleteAll('/', this.Domain_URL);
  this.cookieService.delete('ISTCookie2','/');
  this.cookieService.delete('ASP.NET_SessionId','/');
}

}
