import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedService } from '../Shared/shared.service';
import { revokeOtpModel } from '../model/user.model';
import { LoginService } from '../login/login.service';


const successNote: string = 'Your OTP code has been revoked.';
const failNote: string = 'There was an error revoking your OTP code.';


@Component({
  selector: 'app-revoke-otp',
  templateUrl: './revoke-otp.component.html',
  styleUrls: ['./revoke-otp.component.css'],
  providers: [revokeOtpModel]
})
export class RevokeOtpComponent implements OnInit {

  email: string = '';
  otpSent: string = '';
  iPAddress1: string = '';
  iPAddress2: string = '';
  message: string = "";

  constructor(
    private route: ActivatedRoute
    , private router: Router
    , private sharedService: SharedService
    , private loginService: LoginService
    , private revokeModel: revokeOtpModel
  ) { }

  ngOnInit(): void {    
    console.log('Inside RevokeOtpComponent');
    this.email = this.route.snapshot.paramMap.get('email');
    this.otpSent = this.route.snapshot.paramMap.get('OTPSent');
    this.iPAddress1 = this.route.snapshot.paramMap.get('ip');

    console.log('otpSent - ' + this.otpSent);
    console.log('iPAddress1 - ' + this.iPAddress1);

    this.revokeModel.Fromaddress = this.email;
    this.revokeModel.OTPSend = this.otpSent;
    this.revokeModel.iPRequest = this.iPAddress1;
    this.getIpdata();   
  }


  async RevokeOTPEmailDefinitionsMail() {
    this.loginService.RevokeOTPEmailDefinitionsMail(this.revokeModel).subscribe(data => {
      var resp = data;
      console.log('resp-' + resp)
      if (resp == "true") {
        this.message = successNote;
      }
      else {
        this.message = failNote;
      }
      return;
    });
  }



  async getIpdata() {
    console.log('Inside getIpdata');
    this.sharedService.getIPAddress1().subscribe(data => {
      if (data["IPv4"] != undefined) {
        this.iPAddress2 = data["IPv4"];
        console.log('iPAddress2 - ' + this.iPAddress2);
        this.revokeModel.iPRevoke = this.iPAddress2;     
        this.RevokeOTPEmailDefinitionsMail();
      }
    },
      error => {
        this.getIpdata2();
      }
    );
  }
  async getIpdata2() {
    console.log('Inside getIpdata2');
    this.sharedService.getIPAddress2().subscribe(data => {
      if (data["ip"] != undefined) {
        this.iPAddress2 = data["IPv4"];
        console.log('iPAddress2 - ' + this.iPAddress2);
        this.revokeModel.iPRevoke = this.iPAddress2;     
        this.RevokeOTPEmailDefinitionsMail();
      }
    },
      error => {
        //this.sendEmailDefinitionData();
      }
    );
  }

}
