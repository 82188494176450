
<form novalidate [formGroup]="projectRefAndBudgetForm">
    
    <div class="row isr">
            <div class="col-lg-6">
            <div class="card card-primary">
                <div class="card-header">
                    <div class="card-title">Project Reference And Budget</div>
                </div>
            </div>
            <div class="card card-primary">
                <div class="card-block"> 
                    <div class="row">
                        <div class="col-lg-7">
                            <label>Is this request part of a project?:</label>
                        </div>
                        <div class="col-lg-5">
                            <input type="radio" formControlName="isPartOfProject" value="Yes" [ngClass]="{ 'is-invalid': submitted && validated.isPartOfProject.errors }">
                            &nbsp;<label>Yes</label>&nbsp;&nbsp;
                            <input type="radio" formControlName="isPartOfProject" value="No" [ngClass]="{ 'is-invalid': submitted && validated.isPartOfProject.errors }" (change)="ChangeisPartOfProject()" >
                            &nbsp;<label>No</label>&nbsp;  
                            <!-- <div *ngIf="submitted && validated.isPartOfProject.errors" class="ValidationLabel">
                                <div *ngIf="validated.isPartOfProject.errors.required">Please select is this request part of a project?.</div>
                               </div>                                                 -->
                    </div>
                    </div>        
                    <div *ngIf="projectRefAndBudgetForm.value.isPartOfProject=='Yes'" class="row">
                        <div class="col-lg-7">
                            <label>Is this request related to an existing Ericsson project?:</label>
                        </div>
                        <div class="col-lg-5">
                            <input type="radio" formControlName="IsExistingEricssonProject" value="Yes"  [ngClass]="{ 'is-invalid': submitted && validated.IsExistingEricssonProject.errors }">
                            &nbsp;<label>Yes</label>&nbsp;&nbsp;
                            <input type="radio" formControlName="IsExistingEricssonProject" value="No"  [ngClass]="{ 'is-invalid': submitted && validated.IsExistingEricssonProject.errors }" (click)="ChangeIsExistingEricssonProject()" >
                            &nbsp;<label>No</label>&nbsp;                                                
                    </div>
                    <!-- <div *ngIf="submitted && validated.IsExistingEricssonProject.errors" class="ValidationLabel">
                        <div *ngIf="validated.IsExistingEricssonProject.errors.required">Please select is this request related to an existing Ericsson project?.</div>
                       </div>     -->
                    </div>   
                    <div *ngIf="projectRefAndBudgetForm.value.isPartOfProject=='Yes' && (projectRefAndBudgetForm.value.IsExistingEricssonProject=='Yes' || projectRefAndBudgetForm.value.IsExistingEricssonProject=='No')" class="row">
                        <div class="col-lg-7"><label>{{relatedProjectText}}</label></div>             
                    </div>
                    <div *ngIf="projectRefAndBudgetForm.value.isPartOfProject=='Yes' && (projectRefAndBudgetForm.value.IsExistingEricssonProject=='Yes' || projectRefAndBudgetForm.value.IsExistingEricssonProject=='No')" class="row">
                        <div class="col-lg-7">
                            <label>Project name:</label>
                        </div>
                        <div class="col-lg-5">
                            <input type="text" formControlName="projectNameRequest" [ngClass]="{ 'is-invalid': submitted && validated.projectNameRequest.errors }">                                                                       
                    </div>
                    <!-- <div *ngIf="submitted && validated.projectNameRequest.errors" class="ValidationLabel">
                        <div *ngIf="validated.projectNameRequest.errors.required">Please enter project name?.</div>
                       </div>  -->
                    </div>  
                    <div *ngIf="projectRefAndBudgetForm.value.isPartOfProject=='Yes' && (projectRefAndBudgetForm.value.IsExistingEricssonProject=='Yes' || projectRefAndBudgetForm.value.IsExistingEricssonProject=='No')" class="row">
                        <div class="col-lg-7">
                            <label>Project Id:</label>
                        </div>
                        <div class="col-lg-5">
                            <input type="text" formControlName="projectID" [ngClass]="{ 'is-invalid': submitted && validated.projectID.errors }">                                                                       
                    </div>
                    <!-- <div *ngIf="submitted && validated.projectID.errors" class="ValidationLabel">
                        <div *ngIf="validated.projectID.errors.required">Please enter project id?.</div>
                       </div>  -->
                    </div>  
                    <div *ngIf="projectRefAndBudgetForm.value.isPartOfProject=='Yes' && projectRefAndBudgetForm.value.IsExistingEricssonProject=='Yes'" class="row">
                        <div class="col-lg-7">
                            <label>Is the work required for this request included in the scope of the Ericsson project?:</label>
                        </div>
                        <div class="col-lg-5">
                            <input type="radio" formControlName="ericssonProject" value="Yes" [ngClass]="{ 'is-invalid': submitted && validated.ericssonProject.errors }">
                            &nbsp;<label>Yes</label>&nbsp;&nbsp;
                            <input type="radio" formControlName="ericssonProject" value="No" [ngClass]="{ 'is-invalid': submitted && validated.ericssonProject.errors }">
                            &nbsp;<label>No</label>&nbsp;                                                
                    </div>
                    <!-- <div *ngIf="submitted && validated.ericssonProject.errors" class="ValidationLabel">
                        <div *ngIf="validated.ericssonProject.errors.required">Please select is the work required for this request included in the scope of the Ericsson project?.</div>
                       </div>  -->
                    </div>                  
                </div>                       
                </div>
                <div class="card card-primary">
                    <div class="card-block"> 
                        <div class="row">
                            <div class="col-lg-6">
                                <button type="submit" class="button-design" (click)="GoToPreviousView()">Previous</button>
                            </div>
                            <div class="col-lg-6" style="text-align: right;" >
                                <button type="submit" class="button-design" (click)="GoToNextView()" >Next</button>
                            </div>
                        </div>                                
                    </div>                       
                    </div>                    
            </div>  

            <div class="col-lg-6">
            <div style="height: 40px;"></div>              
                        <div class="row">
                                <div *ngIf="submitted && validated.isPartOfProject.errors" class="ValidationLabel">
                                    <div *ngIf="validated.isPartOfProject.errors.required">Please select is this request part of a project?.</div>
                                   </div>   
                        </div>
                        <div class="row">
                            <div *ngIf="submitted && validated.IsExistingEricssonProject.errors && projectRefAndBudgetForm.value.isPartOfProject=='Yes'" class="ValidationLabel">
                                <div *ngIf="validated.IsExistingEricssonProject.errors.required">Please select is this request related to an existing Ericsson project?.</div>
                               </div>   
                        </div>
                        <div class="row">
                            <div *ngIf="submitted && validated.projectNameRequest.errors && (projectRefAndBudgetForm.value.isPartOfProject=='Yes' && (projectRefAndBudgetForm.value.IsExistingEricssonProject=='Yes' || projectRefAndBudgetForm.value.IsExistingEricssonProject=='No'))" class="ValidationLabel">
                                <div *ngIf="validated.projectNameRequest.errors.required">Please enter project name?</div>
                               </div> 
                        </div>
                        <div class="row">
                            <div *ngIf="submitted && validated.projectID.errors && (projectRefAndBudgetForm.value.isPartOfProject=='Yes' && (projectRefAndBudgetForm.value.IsExistingEricssonProject=='Yes' || projectRefAndBudgetForm.value.IsExistingEricssonProject=='No'))" class="ValidationLabel">
                                <div *ngIf="validated.projectID.errors.required">Please enter project id?</div>
                               </div> 
                        </div>
                        <div class="row">
                            <div *ngIf="submitted && validated.ericssonProject.errors && (projectRefAndBudgetForm.value.isPartOfProject=='Yes' && projectRefAndBudgetForm.value.IsExistingEricssonProject=='Yes')" class="ValidationLabel">
                                <div *ngIf="validated.ericssonProject.errors.required">Please select is the work required for this request included in the scope of the Ericsson project?.</div>
                               </div> 
                        </div>
                        <div class="row">

                        </div>
            </div>
    </div>   
    </form>