import { Injectable } from "@angular/core";
export class AssigneeModel{
    task:number;
    assignee:String;
    date:String;
}
export class RequestTemplateFileModel extends AssigneeModel{
    templateFile:Blob;
    templateFileType:String;
    templateFileName:String;
}
export class RequestOfferFileModel extends RequestTemplateFileModel{
    OfferDocument:Blob;
    OfferDocumentFileType:String;
    OfferFileAddedOn:Date;
    OfferDocSentOn:Date;
}
//export class RequestModel extends RequestOfferFileModel {
    @Injectable()
export class RequestModel{
    requestNumber:String='';
    tor:String='';
    subtor:String='';
    shortDesc:String='';
    requestType:String='';
    requestSubType:String='';
    requestTypeText:String='';
    requestSubTypeText:String='';
    addInfo:String='';
    longDescription:String='';
    requestCreatedOn:Date=new Date('1/1/0001');
    requestCreatedBy:String='';
    requestAcceptedOn:Date=new Date('1/1/0001');
    requestAcceptedBy:number;
    requestedDeliveryDate:Date=new Date('1/1/0001');
    requestTTL:number;
    status:number;
    hasApprovers:Boolean=false;
    closedOn:Date=new Date('1/1/0001');
    closedBy:String='';
    rejectionReason:String='';
    completedDescription:String='';
    catsCode:String='';
    requestRemarks:String='';
    isPartOfProject:Boolean=false;
    ericssonProject:Boolean=false;
    projectID:String='';
    projectNameRequest:String='';
    customerBudgetOwner:String='';
    customerBudgetProjectCode:String='';
    urgent:Boolean=false;
    authorized:Boolean=false;

    templateFile:any =[];

    requestAcceptance:Boolean = false;

    //templateFile:Blob;
    templateFileType:String='';
    templateFileName:String='';

    //OfferDocument:Blob;
    OfferDocumentFileType:String='';
    OfferFileAddedOn:Date;
    OfferDocSentOn:Date;

    //Assignee
    task:number;
    assignee:String='';
    date:String='';

    userName:String='';
    devmode:boolean = false;
    fullUserName:String = '';
    isParentRequest:boolean = false;
    serviceIdList : any = [];
    myManager:String='';
    serviceList:serviceModel[] = [];

    //RequestDefinition

    //UserInfo

}


export class sampleReq{
    requestNumber:String;
}

export class serviceModel{
    id:number;
    customerID:number;
    service:string;
}